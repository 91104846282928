import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media } from '../../theme/styled-theme';
import iconDriver from './assets/value-driver.svg';
import iconCustomize from './assets/value-customize.svg';
import iconPrice from './assets/value-price.svg';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const Root = styled.div`
  display: flex;
  font-family: ${props => props.theme.fontSourceSansPro};
  justify-content: space-between;
  background-color: ${props => props.isHomepage ? 'rgba(0,179,59,0.10)' : '#ffffff'};
  border-radius: ${props => props.isHomepage ? '4px' : '0'};
  border-bottom: ${props => props.isHomepage ? 'none' : '1px solid rgba(180, 180, 180, 0.4)'};
  padding: 1rem 2.75rem;

  ${media.forPhoneOnly`
    display: none;
  `}
`;

const ValueContainer = styled.div`
  display: flex;
  padding: 0.25rem;
  flex: 1 0 0;
  margin: 0 1rem 0 0;
  &:last-of-type {
    margin: 0;
  }
`;

const Title = styled.h4`
  font-size: 1.125rem;
  font-weight: 700;
  color: #343434;
  margin: 0 0 4px;
`;

const Description = styled.p`
  font-size: 0.875rem;
  color: #595959;
  margin: 0;
`;

const Offer = styled.h4`
  flex: 0.8 0 0;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 1rem 0 0;
  color: #343434;

  ${media.forTabletLandscapeOnly`
    display: none;
  `}

  ${media.forTabletPortraitOnly`
    display: none;
  `}
`;

const Value = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.img`
  margin-right: 0.75rem;
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const ValuesBanner = ({ homepage }) => {
  const { t } = useTranslation('new-version');

  return (
    <Root isHomepage={homepage}>
      { homepage ? null : <Offer>{t('COMMON.VALUES.INTRODUCTION')}</Offer> }
      <ValueContainer>
        <Icon src={iconDriver} alt="selected drivers" />
        <Value>
          <Title>{t('COMMON.VALUE_1.TITLE')}</Title>
          <Description>{t('COMMON.VALUE_1.DESCRIPTION')}</Description>
        </Value>
      </ValueContainer>

      <ValueContainer>
        <Icon src={iconPrice} alt="instant quotaion on custom tour" />
        <Value>
          <Title>{t('COMMON.VALUE_2.TITLE')}</Title>
          <Description>{t('COMMON.VALUE_2.DESCRIPTION')}</Description>
        </Value>
      </ValueContainer>

      <ValueContainer>
        <Icon src={iconCustomize} alt="all customizable" />
        <Value>
          <Title>{t('COMMON.VALUE_3.TITLE')}</Title>
          <Description>{t('COMMON.VALUE_3.DESCRIPTION')}</Description>
        </Value>
      </ValueContainer>
    </Root>
  );
};

ValuesBanner.propTypes = {
  homepage: PropTypes.bool,
};

ValuesBanner.defaultProps = {
  homepage: false,
};

export default ValuesBanner;
